/* .App {
  text-align: center;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  /* Optional: Includes padding and border in the element's total width and height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.no-underline {
  text-decoration: none;
  color: inherit;
  font-size: 20px;
  /* Optional: This ensures the text color is inherited from the parent */
}

/* Style for the <ul> element */
.custom-list {
  list-style-type: none;
  /* Remove default bullets */
  /* background-color: #f9f9f9; */
  /* Background color for the list */
}

/* Style for the <li> elements */
.custom-list li {
  padding: 10px 20px;
  border-bottom: 0px solid #ddd;
  /* Add a border between items */
}

.custom-list li.active {
  background-color: #007bff;
  /* Change background color when active */
  color: white;
  /* Change text color to white */
}

/* Style for the <a> elements inside <li> */
.custom-list li a {
  text-decoration: none;
  /* Remove underline */
  color: #333;
  /* Text color */
}

.form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  background-color: rgb(238, 238, 238);
}

.container {
  width: 400px;
}

.form {
  margin: 15px 0px;
}

.draw-label {
  width: 30%;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 400;
  line-height: 31px;
  color: #555;

}

.draw-input-box {
  width: 100%;
  margin-bottom: 10px;
}

.draw-input {
  width: 100%;
  padding: 5px;
  border: 1.5px solid #555;
  ;
  border-radius: 4px;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
  font-size: 14px;
  margin-bottom: 10px;
}

.userInfo {
  border: 0px solid black;
  width: 30%;
  color: grey;
}

.input:focus {
  border-color: #3286e0;
  /* Change border color when focused */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.button {
  padding: 12px 50px;
  background-color: #3286e0;
  border: none;
  color: whitesmoke;
  border-radius: 5px;
  font-size: 13px;
  width: 100%;
  font-size: 15px;
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

.radio-label {
  width: 100px;
  /* Adjust the width as needed */
  margin-right: 10px;
  /* Add some space between the labels */
  padding: 5px;
  /* Add some padding for better spacing */
}

.custom-select {
  max-width: 11rem;
}

.quizListContainer {
  display: flex;
  flex-direction: row;
}

.quizList {
  background-color: rgb(245, 179, 81);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 270px;
  height: 150px;
  border-radius: 10px;
  margin: 10px auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fabric .empimg {
  background-color: #0078d6;
}

.material .empimg {
  background-color: #e3165b;
}

.highcontrast .empimg {
  background-color: #ffd939;
}

_:-ms-fullscreen,
:root .highcontrast .e-userimg.sf-icon-Male::before,
_:-ms-fullscreen,
:root .highcontrast .e-userimg.sf-icon-FeMale::before {
  color: black;
}

.highcontrast .e-userimg.sf-icon-Male::before,
.highcontrast .e-userimg.sf-icon-FeMale::before {
  color: black;
}

.bootstrap .empimg {
  background-color: #0078d6;
}

.bootstrap4 .empimg,
.bootstrap5 .empimg {
  background-color: #0078d6;
}

.tailwind .empimg {
  background-color: #4f46e5;
}

.fluent .empimg {
  background-color: #106EBE;
}

#Emptext {
  top: -12px;
  left: 41px;
  position: relative;
}

.empimg {
  height: 27px;
  width: 29px;
  border-radius: 14px;
  background-color: #e3165b;
}

#overviewgrid.e-rtl #Emptext {
  top: -12px;
  left: -41px;
  position: relative;
}

.e-userimg.sf-icon-Male::before,
.e-userimg.sf-icon-FeMale::before {
  font-size: 13px;
}

.e-userimg.sf-icon-Male,
.e-userimg.sf-icon-FeMale {
  margin: 0px 8px;
  line-height: 26px;
  color: white;
  font-size: 13px;
}

.e-userimg.sf-icon-Male::before {
  content: '\e700';
}

.e-userimg.sf-icon-FeMale::before {
  content: '\e701';
}


.highcontrast #overviewgrid td.e-rowcell.e-selectionbackground.e-active {
  background-color: transparent;
  color: white;
}

.e-bigger #overviewgrid .statustxt {
  position: relative;
}

#overviewgrid td.e-rowcell.e-selectionbackground.e-active {
  background-color: transparent;
}

.Mapimage .e-image {
  height: 13px;
  width: 14px;
}

td.e-rowcell .statustxt.e-activecolor {
  color: #00cc00;
  position: relative;
  top: 9px;
}

td.e-rowcell .statustxt.e-inactivecolor {
  color: #e60000;
  position: relative;
  top: 9px;
}

.bootstrap5 td.e-rowcell .statustxt.e-inactivecolor,
.bootstrap5 td.e-rowcell .statustxt.e-activecolor {
  top: 8px;
}

.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-activecolor,
.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-inactivecolor {
  position: relative;
  top: 1.5px;
}

.e-bigger.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-activecolor,
.e-bigger.bootstrap5 .e-checkboxfiltertext .statustemp .statustxt.e-inactivecolor {
  top: -0.5px;
}

.bootstrap5 .e-checkboxfiltertext #Trusttext {
  margin-left: -4px;
}

.statustemp.e-inactivecolor {
  background-color: #ffd7cc;
  width: 64px;
}

.statustemp.e-activecolor {
  background-color: #ccffcc;
  width: 57px;
}

.statustxt.e-activecolor {
  color: #00cc00;
}

.statustxt.e-inactivecolor {
  color: #e60000;
}

.statustemp {
  position: relative;
  height: 19px;
  border-radius: 5px;
  text-align: center
}

.highcontrast #msg {
  color: #FFFFFF
}

#msg {
  font-family: "Segoe UI", "GeezaPro", "DejaVu Serif", sans-serif;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.95;
  color: #333;
  float: right;
  margin-top: 3px;
}

.fabric-dark #msg,
.bootstrap-dark #msg,
.tailwind-dark #msg,
.bootstrap5-dark #msg,
.material-dark #msg,
.material3-dark #msg,
.fluent-dark #msg,
.fluent2-dark #msg,
.fluent2-highcontrast #msg {
  color: #ffffff !important;
}

@media only screen and (max-width: 370px) {
  #msg {
    float: left;
    margin-top: 3px;
  }

  #overviewgrid {
    margin-top: 36px;
  }
}

#samplecontainer {
  padding-top: 5px;
}

#myProgress {
  position: relative;
  height: 18px;
  width: 10em;
  text-align: left;
  background-color: white;
}

.highcontrast #overviewgrid #myProgress {
  background-color: black;
}

#myBar {
  position: absolute;
  width: 10%;
  height: 100%;
  background-color: #00b300;
}

#myBar.progressdisable {
  background-color: #df2222;
}

#pbarlabel {
  position: relative;
  left: 10px;
  line-height: 18px;
  font-size: 11px;
  color: white;
}

.custom-rating.e-rating-container .e-rating-item-container,
.e-bigger .custom-rating.e-rating-container .e-rating-item-container .e-rating-icon,
.e-bigger.custom-rating.e-rating-container .e-rating-item-container .e-rating-icon {
  font-size: 11px;
}

.custom-rating.e-rating-container .e-rating-item-container .e-rating-icon {
  -webkit-text-fill-color: lightgrey;
  -webkit-text-stroke: 1px lightgrey;
}

.custom-rating.e-rating-container .e-rating-item-container.e-rating-selected .e-rating-icon {
  background: linear-gradient(to right, #ffa600 var(--rating-value), #ffa600 var(--rating-value));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #ffa600;
}

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

.control-pane {
  padding: 0 20px;
}

.control-pane * {
  font-family: "Lexend", sans-serif;
}

/* #fafafb */
.e-gridheader .e-headercell .e-icons {
  font-size: 16px !important;
  padding: 2px !important;
}

.e-gridheader .e-headercell {
  background-color: #fafafb !important;
  color: #000;
  font-size: 14px !important;
}

.e-gridheader .e-headercell .e-headercelldiv {
  font-size: 14px !important;
}

.e-grid tbody .e-row .e-rowcell {
  color: rgba(0, 0, 0, 0.88) !important;
  font-size: 14px;
}

.e-grid tbody .e-row:hover .e-rowcell {
  background-color: rgba(33, 150, 243, 0.1) !important;
}

.filterIconBox {
  border: 1px solid gray;
  width: 37px;
  min-width: 37px !important;
  height: 37px;
  border-radius: 50%;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: auto;
}

.filterIcon {
  color: black;
  font-size: 14px;
}

.filterIconBox:hover {
  background-color: black;
}

.filterIconBox:hover .filterIcon {
  color: whitesmoke;
}

.columns {
  border: 0px solid black;
  margin: 4px 0px 4px 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.ant-input-search .ant-input:focus {
  color: black;
}

.custom-search-input .ant-input:focus {
  color: black !important;
  /* Ensure the color is applied */
}

.ant-drawer-header {
  padding: 6px 16px !important
}

.ant-drawer-header-title {
  flex-direction: row-reverse
}

.ant-drawer-header .ant-drawer-title {
  font-size: 17px;
  font-weight: 600
}

.ant-drawer-body {
  padding: 12px 16px 15px !important
}

.ant-drawer-md .ant-drawer-content-wrapper {
  width: 100% !important;
  max-width: 420px
}

.ant-drawer-close {
  margin-right: 0 !important
}

.ant-drawer.resizableDrawer.resizing .ant-drawer-content-wrapper {
  transition: none
}

.ant-drawer .ant-table-cell {
  white-space: nowrap
}

.sidebar-dragger {
  width: 6px;
  cursor: ew-resize;
  padding: "4px";
  border-top: 1px solid #ddd;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background-color: #fff
}

.sidebar-dragger:hover {
  background-color: #10172b33
}

.ant-drawer-left .sidebar-dragger {
  left: auto;
  right: 0
}


.sidebarTabs .ant-tabs-nav-list .ant-tabs-tab {

  padding: 0px;
  font-size: 13px;
  font-weight: 500;
  color: #212325;
  padding-bottom: 6px
}

.sidebarTabs .ant-tabs-nav-list .ant-tabs-tab-btn:focus {
  color: #212325 !important
}

.sidebarTabs .ant-tabs-nav-list .ant-tabs-tab-active .ant-tabs-tab-btn,
.sidebarTabs .ant-tabs-nav-list .ant-tabs-tab-active span {
  color: #10172b !important
}

.sidebarTabs .ant-tabs-nav-list .ant-tabs-ink-bar {
  background: #10172b !important
}


.ant-form.ant-form-horizontal .ant-form .ant-row {
  align-items: center
}

.ant-form.ant-form-horizontal .ant-form .ant-form-label>label {
  margin-bottom: 0 !important
}

.ant-form {
  margin-bottom: 5px
}

.ant-form .ant-row .ant-form-control-input+div .ant-form-explain+div {
  height: auto !important
}

.ant-form .ant-form-margin-offset,
.ant-form .ant-form-control-input .ant-form {
  margin-bottom: 0 !important
}

.ant-form .ant-form-label {
  padding-bottom: 0
}

.ant-form .ant-form-label>label {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 31px;
  color: #555;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inherit;
  text-align: left;
  padding-right: 5px
}

.ant-form .ant-form-label>label:after {
  display: none
}

.ant-form .ant-form-label>label sup {
  color: #dc4c64;
  margin-left: 5px;
  font-weight: 700;
  font-size: 12px;
  top: -.5em;
  line-height: normal
}

.ant-form .ant-input-control {
  color: #555;
  border: 1px solid #e0e0e0;
  padding: 6px 12px;
  border-radius: 6px;
  height: 31px;
  font-size: 14px;
  width: 100%;
  font-weight: 600;
  outline: 0
}

.ant-form .ant-input-control:hover {
  background: #e8ebf2
}

.ant-form .ant-input-control::-webkit-input-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form .ant-input-control:-moz-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form .ant-input-control::-moz-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form .ant-input-control:-ms-input-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form .ant-input-control:-webkit-autofill,
.ant-form .ant-input-control:-webkit-autofill:hover,
.ant-form .ant-input-control:-webkit-autofill:focus,
.ant-form .ant-input-control:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #555555;
  transition: background-color 5000s ease-in-out 0s
}

.ant-form .ant-input-control:focus,
.ant-form .ant-input-control:focus-within {
  background-color: transparent;
  border-color: #10172b !important;
  box-shadow: 0 0 0 3px #10172b1a !important
}

.ant-form .ant-input-control.ant-input-disabled,
.ant-form .ant-input-control:disabled,
.ant-form .ant-input-control.disabled {
  cursor: default;
  background: #fff !important
}

.ant-form .ant-input-control.ant-input-disabled:hover,
.ant-form .ant-input-control:disabled:hover,
.ant-form .ant-input-control.disabled:hover {
  background: #fff !important
}

.ant-form .ant-input-control.defaultInput:disabled {
  background: #f8f9fb !important
}

.ant-form .ant-input-control.defaultInput:disabled:hover {
  background: #f8f9fb !important
}

.ant-form .ant-input-control.bg-success,
.ant-form .ant-input-control.bg-success:hover {
  background-color: #d2e8d3
}

.ant-form .ant-input-control.bg-danger,
.ant-form .ant-input-control.bg-danger:hover {
  background-color: #f2dede
}

.ant-form .ant-input-control.ant-picker-status-error:not(.ant-picker-disabled) {
  border-color: #e0e0e0
}

.ant-form .ant-input-control.ant-picker-status-error:not(.ant-picker-disabled) .ant-picker-suffix {
  color: #00000040
}

.ant-form .ant-input-control .ant-input {
  color: #555;
  font-size: 14px;
  font-weight: 600
}

.ant-form .ant-input-control .ant-input::-webkit-input-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form .ant-input-control .ant-input:-moz-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form .ant-input-control .ant-input::-moz-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form .ant-input-control .ant-input:-ms-input-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form .ant-input-control .ant-input:-webkit-autofill,
.ant-form .ant-input-control .ant-input:-webkit-autofill:hover,
.ant-form .ant-input-control .ant-input:-webkit-autofill:focus,
.ant-form .ant-input-control .ant-input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #555555;
  transition: background-color 5000s ease-in-out 0s
}

.ant-form .ant-input-control.taskManageInput:not(:focus) {
  background: #f8f9fb;
  border-color: transparent
}

.ant-form textarea.ant-input-control {
  height: 120px;
  min-height: 80px;
  font-family: Lexend Deca, sans-serif;
  width: 100% !important
}

.ant-form textarea.ant-input-control::-webkit-input-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form textarea.ant-input-control:-moz-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form textarea.ant-input-control::-moz-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form textarea.ant-input-control:-ms-input-placeholder {
  color: #555;
  font-size: 14px;
  font-weight: 400
}

.ant-form .ant-switch {
  background: #c5c5c5
}

.ant-form .ant-switch.ant-switch-checked {
  background: #10172b;
  outline: 0
}

.ant-form .ant-switch.ant-switch-checked:hover {
  background: #10172b
}

.ant-form .ant-switch.ant-switch-checked:focus {
  box-shadow: 0 0 0 3px #10172b1a !important
}

.ant-form .ant-switch.ant-switch-checked .ant-switch-handle:before {
  background: #fff
}

.ant-form .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-checked,
.ant-form .ant-switch.ant-switch-checked .ant-switch-inner .ant-switch-inner-unchecked {
  font-size: 13px;
  font-weight: 500
}

.ant-form .ant-color-picker-trigger {
  justify-content: start
}

.ant-form .ant-color-picker-color-block {
  width: 20px;
  height: 20px
}

.ant-form .ant-color-picker-trigger-text {
  color: #555
}

.ant-form .ant-upload-wrapper {
  width: 100%
}

.ant-form .ant-upload-wrapper .ant-upload-select {
  display: block
}

.ant-form .ant-upload-wrapper .ant-btn:not(.ant-btn-circle) {
  height: 31px;
  border-radius: 6px;
  padding: 6px 12px;
  border-color: #e0e0e0 !important;
  justify-content: start;
  color: #555;
  font-size: 14px !important;
  font-weight: 400
}

.ant-form .ant-upload-wrapper .ant-btn:not(.ant-btn-circle):hover {
  color: #555 !important;
  background-color: #f5f5f5;
  border-color: #e0e0e0 !important
}

.ant-form .ant-upload-wrapper .ant-btn:not(.ant-btn-circle):focus,
.ant-form .ant-upload-wrapper .ant-btn:not(.ant-btn-circle):focus-within {
  background-color: transparent;
  border-color: #10172b !important;
  box-shadow: 0 0 0 3px #10172b1a !important
}

.ant-form .ant-input-control {
  color: #000;
  background: #fff;
  font-size: 16px
}

.ant-form .ant-input-control::-webkit-input-placeholder {
  color: #0000004d
}

.ant-form .ant-input-control:-moz-placeholder {
  color: #0000004d
}

.ant-form .ant-input-control::-moz-placeholder {
  color: #0000004d
}

.ant-form .ant-input-control:-ms-input-placeholder {
  color: #0000004d
}

.ant-switch.ant-switch-checked {
  background: #10172b;
  outline: 0;
}

.draggableList_heading {
  font-weight: 600;
  font-size: 16px;
  display: block;
}

.draggableList_item {
  padding: 9px 10px;

  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.draggableList_item:hover {
  background-color: #f5f5f5;
}

.draggableList_title {
  color: #212325;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
}

.ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 31px;
  line-height: 31px;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: none;
  color: #555555;
  padding: 0 12px;
}

.ant-select.ant-select-single:not(.ant-select-customize-input):hover .ant-select-selector {
  border-color: #e0e0e0 !important;
  background: #e8ebf2;
}

.ant-select .ant-select-arrow .anticon {
  display: none;
}

.ant-select .ant-select-arrow:after {
  content: "\e998";
  font-family: "icomoon";
  color: #555555;
  font-size: 14px;
}

.ant-input-control {
  color: #555555;
  border: 1px solid #e0e0e0;
  padding: 6px 12px;
  border-radius: 6px;
  height: 31px;
  font-size: 14px;
  width: 100%;
  font-weight: 600;
  outline: 0;
}

.ant-input-control:hover {
  background: #e8ebf2;
  border: 1px solid #e0e0e0;
}

.ant-input-control:focus,
.ant-input-control:focus-within {
  background-color: transparent;
  border-color: #10172B !important;
  box-shadow: 0px 0px 0px 3px rgba(16, 23, 43, 0.1) !important;
}

.ant-btn-outline-danger {
  color: #dc4c64;
  border-color: #dc4c64;
}

.ant-btn-sm {
  font-size: 13px;
}

.ant-btn-sm:not(.ant-btn-circle) {
  height: 35px;
  padding: 5px 12px;
}

.ant-btn-light {
  color: #212325;
  background-color: #e8e8e8;
  border-color: #e8e8e8;
}

.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
}

.ant-btn-default {
  background: #ffffff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.88);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);

  &:hover {
    color: #10172B !important;
    border-color: #10172B !important;
  }
}

.ant-btn-primary {
  color: #ffffff;
  background-color: #10172B;
  border-color: #10172B;

  &:hover {
    color: #ffffff !important;
    background-color: #253563 !important;
    border-color: #253563 !important;
    box-shadow: none !important;
  }
}

.ant-btn-outline-dark {
  color: #212325;
  border-color: #212325;

  &:hover {
    color: #ffffff !important;
    background-color: #212325 !important;
    border-color: #212325 !important;
    box-shadow: none !important;
  }
}

.ant-form-item .ant-form-item-label>label {
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  line-height: 31px;
  color: #555555;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inherit;
  text-align: left;
  padding-right: 5px;

  &:after {
    display: none;
  }
}

.inlineLabel .ant-form-item-label {
  flex: 0 0 29.166666666666668%;
  max-width: 29.166666666666668%;
}

.ant-select-selector:focus {
  background-color: transparent;
  border-color: #10172B !important;
  box-shadow: 0px 0px 0px 3px rgba(16, 23, 43, 0.1) !important;
}

.ant-form-item {
  margin-bottom: 5px;
}



:where(.css-dev-only-do-not-override-qshsz7).ant-collapse>.ant-collapse-item>.ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 15px 16px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}



.ant-collapse-content {
  border-top: none !important;
}

.ant-collapse {
  border: none !important;
}

.ant-collapse-header {
  background-color: #ffffff;
  border-bottom: 1px solid #cdcdcd;
  border-radius: 0 !important;
}

.ant-collapse-header-text {
  font-weight: 500;
}

.ant-select-focused:where(.css-dev-only-do-not-override-14i19y2).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #10172B !important;
  box-shadow: 0px 0px 0px 3px rgba(16, 23, 43, 0.1) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #10172B;
  border-color: #10172B;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: #ffffff !important;
}

.tiles {
  width: 300px;
  height: 100px;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;

}

.closeBox {
  border: 1px solid #c9c8c8;
  padding: 4px 8px;
  border-radius: 50%;
}

.closeBox:hover {
  border: 1px solid #10172B;
}

.my-tasks-container {
  width: 100%;
  max-width: 400px;
  height: 710px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.my-tasks-container:hover {
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}

.tasks-header {
  padding: 60px;
  text-align: center;
  color: white;
  background-size: cover;
  background-position: center;
  height: 350px;
}

.tasks-header h2 {
  margin: 0;
  font-size: 24px;
  color: white;
}

.tasks-header p {
  margin: 10px 0 0;
  font-size: 14px;
}

.tasks-header p strong {
  color: #0069d9;
}

.tasks-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  padding: 20px;
}

.task-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.task-card h3 {
  margin: 10px 0;
  font-size: 22px;
}

.task-card p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.icon {
  font-size: 24px;
  color: #8fd8dd;
}

.head {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

}

.head-text {
  padding: 10px 20px;
}

.me-4 {
  margin-right: 1rem !important;
}

.w-25px {
  width: 30px !important;

}

.task-row {
  display: flex;
  justify-content: space-between;
  margin: 15px 20px 20px 20px;
  padding-bottom: 5px;
  border-bottom: 1px dashed #cdcdcd;
}

.fw-bold {
  font-weight: 600 !important;
}

.text-hover-primary {
  transition: color .2s ease;
}

.text-gray-800 {
  color: #1b1b1d !important;
  font-weight: 600;
}

.my-horizontal-container {
  width: 100%;
  max-width: 50%;
  height: 350px;
  background-color: rgb(23, 56, 99);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px;
  
}

.my-horizontal-container:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}

.menuIcon {
  color: whitesmoke;
  font-size: 22px;
  cursor: pointer;
}

.custom-tooltip .ant-tooltip-inner {
  color: white;
  position: relative;
  left: 9px;
  border-radius: 5px;
}

.custom-tooltip .ant-tooltip-arrow {
  display: none; /* Hide the arrow */
}

.custom-tooltip .ant-tooltip {
  background-color: #f5f5f5; /* Background color of tooltip */
  color: #333; /* Text color */
}

.custom-tooltip {
  max-width: 250px; /* Optional: Set a custom max-width for tooltip */
}